<template>
  <div class="home">
    <HomeHeader />
    <div class="content">
          <v-container>
      <v-row>
          <v-col cols="12">
            <div class="about-me text-center">
              <h1>Hi! I'm Asthriona!</h1>
              <p>
                I'm a full-stack web developer, Last seen working on <a :href="lastSeen.url" target="_blank" rel="noopener noreferrer"> <b class="lastSeen">{{ lastSeen.name }}</b> </a> <br>
                Moving between Lille, France and Tokyo, Japan, I'm doing code on the fly (Literally sometimes.), I'm mostly helping on project while working on <a href="https://Yukiko.app" target="_blank" rel="noopener noreferrer">Yukiko.app</a>
              </p>
              <h1>Fact about me:</h1>
              <p>
                I'am a massive fan of music, I allways listen to something on YouTube Music or Spotify. I've been last seen playing <b class="lastSeen">{{ music[0].track }}</b> from <b>{{ music[0].artist }}</b> <br>
                And I think I'm coding a lot. I've been coding for <b>480 hours</b> in <b>2021</b> <br>
                You can read more about me in the <router-link :to="{ name: 'About' }">About page</router-link>
                <!-- I also do love stats. Like... I mean... a lot! and you know. music and stats works together, so heres some minimal stats from LastFM!
                <ul class="justify">
                  <li>Total Count: {{ music[1].playcount }}</li>
                  <li>Top Artist: {{ music[1].top_artist.name }}</li>
                  <li>{{ music[1].top_artist.name }} play count: {{ music[1].top_artist.playcount }}</li>
                  <li>Top Track: {{ music[1].top_track.name }}</li>
                  <li>Top Track play count: {{ music[1].top_track.playcount }}</li>
                </ul> -->
              </p>
            </div>
          </v-col>
          <Work />
      </v-row>
    </v-container>
    </div>
  </div>
</template>

<script>
import Work from '../components/HomePage/Works.vue'
import HomeHeader from '../components/HomePage/HomeHeader.vue'
import axios from 'axios'
export default {
  name: 'Home',
  components: {
    Work,
    HomeHeader
  },
  data () {
    return {
      lastSeen: '',
      music: [],
      wakatime: '',
      loading: false
    }
  },
  mounted () {
    this.loading = true
    axios.get('https://api.github.com/users/Asthriona/events')
      .then(res => {
        this.lastSeen = res.data[0].repo
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
      })
      .then(() => {
        axios.get('http://localhost:3030/proxy/lastfm')
          .then(res => {
            this.music.push(res.data)
            this.loading = false
          })
          .catch(err => {
            console.log(err)
          })
      })
  }
}
</script>

<style scoped>
/* get font in @assets/fonts/AsthrionaFont.ttf and set it as Asthriona */
#app {
  scroll-behavior: smooth;
}
.home {
  background-color: #212226;
  color: whitesmoke;
}
/* remove link decoration from .lastSeen */
.lastSeen {
  text-decoration: none;
  color: whitesmoke;
}
</style>
