<template>
  <v-app dark>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">
        <router-link to="/">
        <v-img
          alt="Asthriona Logo"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="100"
          src="https://cdn.nishikino.xyz/asthriona/global/logo_black.png"
          width="300"
        />
        </router-link>
      </div>
      <v-btn
        to="/"
        text>
        Home
        </v-btn>
        <v-btn
        to="/blog"
        text>
        Blog
        </v-btn>

      <v-spacer></v-spacer>
      <div class="noFuckingUser" v-if="!user">
        <v-btn
      class="mr-4"
      :to="{ name: 'Login' }"
      text>
        <v-icon>mdi-login</v-icon>
        <span class="hidden-sm-and-down"> Login</span>
      </v-btn>
      <v-btn
      :to="{ name: 'Register' }"
      text>
        <v-icon>mdi-account-plus</v-icon>
        <span class="hidden-sm-and-down"> Register</span>
      </v-btn>
      </div>
      <div class="godDangUser" v-else>
          <v-btn
          v-if="user.isAdmin"
          class="mr-4"
          :to="{ name: 'Admin' }"
          text>
          <v-icon>mdi-shield-crown</v-icon></v-btn>
        <v-btn
      class="mr-4"
      :to="{ name: 'Profile' }"
      text>
       <v-avatar size="30">
          <v-img :src="user.avatar"></v-img>
        </v-avatar>
        <span class="hidden-sm-and-down"> {{ user.username }}
          <v-icon v-if="!user.badge && (user.isVerified || user.isAdmin)">mdi-check-decagram-outline</v-icon>
          <v-icon v-else-if="user.badge === 'admin' && user.isAdmin == true">mdi-shield-crown</v-icon>
          <v-icon v-else-if="user.badge === 'verified' && user.isVerified || user.isAdmin">mdi-check-decagram-outline</v-icon>
        </span>
      </v-btn>
      <v-btn
      @click="logout"
      text>
        <v-icon>mdi-logout</v-icon>
      </v-btn>
      </div>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
    <!-- Footer -->
    <TheFooter />
  </v-app>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import TheFooter from './components/MainParts/TheFooter.vue'
export default {
  name: 'App',

  components: {
    TheFooter
  },
  mounted () {
    this.authenticate()
    // :style="{ background: $vuetify.theme.themes[theme].background }
    this.$vuetify.theme.themes.dark = true
  },
  methods: {
    ...mapActions({
      authenticate: 'auth/authenticate',
      authLogout: 'auth/logout'
    }),
    logout () {
      const location = window.location.pathname
      this.authLogout()
        .then(() => {
          localStorage.clear()
          if (!location === '/') {
            this.$router.push('/')
          } else {
            this.$router.go()
          }
        })
    }
  },
  computed: {
    ...mapGetters('users', { findUserInStore: 'find' }),
    ...mapState('auth', { user: 'user' }),
    user () {
      return this.findUserInStore(this.$store.state.users.user).data[0]
    },
    theme () {
      return this.$vuetify.theme.dark
    }
  }
}
</script>

<style>
  #app {
    background-color: #212226 !important;
  }
</style>
