/* eslint-disable no-sequences */
<template>
    <v-container>
      <v-layout column>
        <v-row>
        <v-col
        cols="3">
          <v-card
          dark>
            <v-card-title>
              <v-col
              cols="12">
                <v-avatar
              size="250">
                <v-img :src="profile.avatar"></v-img>
              </v-avatar>
              </v-col>
            </v-card-title>
            <v-card-action>
              <v-col cols="12">
                <div class="userInfo">
              <span class="display-2">
                {{ profile.displayname }}
          <v-icon v-if="!profile.badge && (profile.isVerified || profile.isAdmin)">mdi-check-decagram-outline</v-icon>
          <v-icon v-else-if="profile.badge === 'admin' && profile.isAdmin == true">mdi-shield-crown</v-icon>
          <v-icon v-else-if="profile.badge === 'verified' && profile.isVerified == true">mdi-check-decagram-outline</v-icon>
              </span>
              <br>
              <span class="display-1">@{{ profile.username }}</span>
              </div>
              </v-col>
            </v-card-action>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card dark>
            <v-card-title>
              <span class="headline">
                Users Infos:
              </span>
            </v-card-title>
            <v-card-actions class="text-wrap">
              <!-- <v-col cols="6">
                <div class="userInfo">
              <span class="display-1">
                {{ profile.posts || 0 }}
              </span>
              <br>
              <span class="display-1">
                Posts
              </span>
              </div>
              </v-col> -->
              <v-col cols="6">
                <div class="userInfo">
              <span class="display-1">
                {{ profile.comments || 0 }}
              </span>
              <br>
              <span class="display-1">
                Comments
              </span>
              </div>
              </v-col>
              <!-- <v-col cols="6">
                <div class="userInfo">
              <span class="display-1">
                {{ profile.likes || 0 }}
              </span>
              <br>
              <span class="display-1">
                Likes
              </span>
              </div>
              </v-col>
              <v-col cols="6">
                <div class="userInfo">
              <span class="display-1">
                {{ profile.followers || 0 }}
              </span>
              <br>
              <span class="display-1">
                Followers
              </span>
              </div>
              </v-col>
              <v-col cols="6">
                <div class="userInfo">
              <span class="display-1">
                {{ profile.following || 0 }}
              </span>
              <br>
              <span class="display-1">
                Following
              </span>
              </div>
              </v-col> -->
              <v-col cols="6">
                <div class="userInfo">
              <span class="display-1">
                Member since:
              </span>
              <br>
              <span class="display-1">
                {{ profile.createdAt }}
              </span>
              </div>
              </v-col>
            </v-card-actions>
          </v-card>
          </v-col>
          <v-col cols="6">
            <v-card dark>
              <v-card-title>
                <span class="headline">
                  About:
                </span>
              </v-card-title>
              <v-card-text class="text-wrap headline">
                {{ profile.bio }}
              </v-card-text>
            </v-card>
          </v-col>
          </v-row>
      </v-layout>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import store from '../../../store'
export default {
  name: 'users-profile',
  mounted () {
    store.dispatch('users/get', this.$route.params.username)
      .then((data) => {
        console.log(data)
      })
  },
  computed: {
    ...mapGetters('users', { findUser: 'get' }),
    profile () {
      return this.findUser(this.$route.params.username)
    }
  }
}
</script>
