import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      dark: {
        primary: '#38529c',
        secondary: colors.orange.base,
        accent: colors.purple.base,
        error: colors.red.base,
        warning: colors.amber.base,
        info: colors.cyan.base,
        success: colors.green.base,
        background: '#212226'
      },
      light: {
        primary: '#38529c',
        secondary: colors.orange.base,
        accent: colors.purple.base,
        error: colors.red.base,
        warning: colors.amber.base,
        info: colors.cyan.base,
        success: colors.green.base,
        background: '#212226'
      }
    }
  }
})
