import Vue from 'vue'
import VueRouter from 'vue-router'
// base routes
import Home from '../views/Home.vue'
import About from '../views/About.vue'
// user routes
import Login from '../views/users/auth/login.vue'
import Register from '../views/users/auth/register.vue'
import Profile from '../views/users/profile/edit-profile.vue'
import userProfile from '../views/users/profile/profile.vue'
// Admin Routes
import AdminHome from '../views/admin/AdminHome.vue'
import AdminUsermanage from '../views/admin/AdminUsermanage.vue'
// blog routes
import BlogHome from '../views/blog/HomeBlog.vue'
// Error Routes
import NotFound from '../views/errors/404.vue'

import wpAdmin from '../views/troll/wp-admin.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'Home',
    component: About
  },
  // Users Routes
  {
    path: '/auth/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/auth/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/user/profile',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/user/profile/:username',
    name: 'User-profile',
    component: userProfile
  },
  // blog routes
  {
    path: '/blog',
    name: 'Blog-Home',
    component: BlogHome
  },
  // Admin routes
  {
    path: '/admin',
    name: 'Admin',
    component: AdminHome
  },
  {
    path: '/admin/users',
    name: 'Admin-Users-Management',
    component: AdminUsermanage
  },
  // Errors Routes
  {
    path: '*',
    name: '404',
    component: NotFound
  },
  // toll routes
  {
    path: '/wp-admin',
    name: 'wp-admin',
    component: wpAdmin
  },
  {
    path: '/wp-login',
    name: 'wp-admin',
    component: wpAdmin
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
