<template>
    <div class="works">
        <v-container dark>
            <v-row>
                <v-layout row>
                    <v-col cols="12">
            <h1 class="headline">Previous Works</h1>
            <p>Here is a list of things I've worked on</p>
          </v-col>
          <v-col v-for="work in works" :key="work.name">
            <v-card
            :loading="loading"
              class="mx-auto my-12"
              max-width="374"
              dark>
              <v-card-title><span>{{ work.title }}</span></v-card-title>
              <v-img max-height="300" :src="work.img"></v-img>
              <v-card-text>
                  {{ work.description }}
              </v-card-text>
              <v-card-actions>
                <v-btn color="primary" :href="work.link" target="_blank" rel="noopener noreferrer">
                  <v-icon>mdi-open-in-new</v-icon>
                  Visit
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
                </v-layout>
            </v-row>
        </v-container>
    </div>
</template>

<script>
export default {
  name: 'Works-Comp',
  data () {
    return {
      loading: false,
      works: [
        {
          title: 'Asthriona.com',
          description: 'My personal website built from the ground up.',
          link: 'https://asthriona.com',
          img: 'https://cdn.nishikino.xyz/asthriona/AshASh.webp'
        },
        {
          title: 'Yukiko.app',
          description: 'Multi Purpose Discord bot',
          link: 'https://Yukiko.app',
          img: 'https://cdn.nishikino.xyz/asthriona/YukikoAsh.webp'
        },
        {
          title: 'The Wall',
          description: 'World of Warcraft Comunity Website',
          link: 'https://TheWall.app',
          img: 'https://cdn.nishikino.xyz/asthriona/TheWallAsh.webp'
        },
        {
          title: 'Eternal Vengeance Discord Bot',
          description: 'Community Discord bot.',
          link: 'https://EternalVengeance.app',
          img: 'https://cdn.discordapp.com/icons/424634199241129995/a63a8bf01fe8e01969d558cf26d383bb?size=4096'
        },
        {
          title: 'Cable Porn',
          description: 'Twitter Account that tweet images from r/CablePorn everyday',
          link: 'https://twitter.com/CablePorn_',
          img: 'https://cdn.asthriona.com/i/2021/12/chrome_28122021-100738AM.png'
        }
      ]
    }
  }
}
</script>
