<template>
  <div class="TheFuckingUserProfile geez">
    <v-container>
      <v-layout column>
        <v-row>
              <v-col
              cols="12">
          <div class="fuck_That_Alert ma-5">
            <v-alert v-if="error" type="error" dismissible>
              {{ error.name }} ({{ error.code }}) - {{ error.message }}</v-alert>
          </div>
              </v-col>
          <!-- START User Infos Cards -->
          <v-col
          cols="5">
          <v-card
          class="ma-5"
          elevation="5"
          max-width="374"
          dark>
            <v-img height="250" :src="user.avatar"></v-img>
            <v-card-title>
                {{ user.displayname }}
          <v-icon v-if="!user.badge && (user.isVerified || user.isAdmin)">mdi-check-decagram-outline</v-icon>
          <v-icon v-else-if="user.badge === 'admin' && user.isAdmin == true">mdi-shield-crown</v-icon>
          <v-icon v-else-if="user.badge === 'verified' && user.isVerified || user.isAdmin">mdi-check-decagram-outline</v-icon>
            </v-card-title>
            <v-card-text>
              <h3>User Information:</h3>
              <ul>
                <li>User ID: {{ user._id }}</li>
                <li>Username: {{ user.username }}</li>
                <li>Display name: {{ user.displayname }}</li>
                <li>Email: {{ user.email }}</li>
                <li>Bio: {{ user.bio }}</li>
                <li>Badge: {{ user.badge ? user.badge : "None" }}</li>
                <li>Account Creation Date: {{ user.createdAt }}</li>
                <li>Last login: {{ user.lastLoginTimeStamp }}</li>
              </ul>
              <v-btn
              :to="`/user/profile/${user._id}`">
                <v-icon>mdi-account-edit</v-icon>
                <span class="hidden-sm-and-down"> View Profile</span>
              </v-btn>
            </v-card-text>
          </v-card>
          </v-col>
      <!-- END User infos Card -->
      <!-- START User update forms -->
      <v-card
      cols="12"
      min-width="40%"
      dark>
      <!-- Display name Form -->
      <v-col
      cols="9">
      <h1>Update Display Name</h1>
      <v-form v-model="DisplaynameValid" @submit.prevent="updateDisplayname">
        <v-text-field
          v-model="form.displayname"
          :placeholder="user.displayname"
          :rules="NotEmptyRule"
          :counter="35"
        ></v-text-field>
        <v-btn type="submit" color="primary" :disabled="!DisplaynameValid">
          Update
        </v-btn>
      </v-form>
      </v-col>
      <!-- Bio Form -->
      <v-col
      cols="8">
      <h1>Update Biography</h1>
      <v-form v-model="validBio" @submit.prevent="updateBio">
        <v-textarea
          v-model="form.bio"
          :placeholder="user.bio"
          :rules="bioRules"
          :counter="255"
        ></v-textarea>
        <v-btn type="submit" color="primary" :disabled="!validBio">
          Update
        </v-btn>
      </v-form>
      </v-col>
        <!-- email Form -->
      <v-col
      cols="9">
      <h1>Change Email</h1>
      <v-form v-model="validEmail" @submit.prevent="updateEmail">
        <v-text-field
          v-model="form.email"
          :placeholder="user.email"
          :rules="emailRules"
        ></v-text-field>
        <v-btn type="submit" color="primary" :disabled="!validEmail">
          Update
        </v-btn>
      </v-form>
      </v-col>
      <!-- Password Form -->
      <v-col
      cols="9">
      <v-form v-model="passwordValid" @submit.prevent="updatePassword">
        <v-text-field
          v-model="form.password"
          :placeholder="'Password'"
          :rules="passwordRule"
        ></v-text-field>
        <v-text-field
          v-model="form.confirmPassword"
          :placeholder="'Confirm Password'"
          :rules="confirmPasswordRule"
        ></v-text-field>
        <v-btn type="submit" color="primary" :disabled="!passwordValid">
          Update
        </v-btn>
      </v-form>
      </v-col>
      <!-- Badge Form (Admin only) -->
      <v-col
      cols="3">
      <v-form v-model="validBadge" @submit.prevent="updateBadge">
        <v-select
          v-model="form.badge"
          :items="badges"
          :disabled="!user.isAdmin"
          label="Badge"
          default-value="None"
        ></v-select>
        <v-btn type="submit" color="primary" :disabled="!user.isAdmin">
          Update
        </v-btn>
      </v-form>
      </v-col>
      <!-- Update Avatar -->
      <v-col
      cols="9">
      <v-form v-model="validAvatar" @submit.prevent="updateAvatar">
        <v-text-field
          v-model="form.avatar"
          :placeholder="'Avatar URL'"
          :rules="avatarRule"
        ></v-text-field>
        <v-btn type="submit" color="primary" :disabled="!validAvatar">
          Update
        </v-btn>
      </v-form>
      </v-col>
        </v-card>
      <!-- END User update forms -->
              </v-row>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'user-profile',
  data () {
    return {
      error: null,
      form: {
        displayname: '',
        bio: '',
        email: '',
        username: '',
        avatar: '',
        password: '',
        passwordConfirm: ''
      },
      badges: [
        'verified',
        'admin'
      ],
      NotEmptyRule: [
        v => !!v || 'This field is required',
        v => v.length <= 50 || 'Display name must be less than 50 characters'
      ],
      bioRules: [
        v => !!v || 'This field is required',
        v => v.length <= 255 || 'Bio must be less than 255 characters'
      ],
      passwordRule: [
        v => !!v || 'This field is required',
        v => v.length >= 6 || 'Password must be at least 6 characters',
        v => /[A-Z]/.test(v) || 'Password must contain at least one capital letter',
        v => /[0-9]/.test(v) || 'Password must contain at least one number',
        v => /[!@#$%^&*]/.test(v) || 'Password must contain at least one special character'
      ],
      confirmPasswordRule: [
        v => !!v || 'This field is required',
        v => v === this.form.password || 'Passwords do not match'
      ],
      emailRules: [
        v => !!v || 'This field is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
      //   avatar rule must be a lonk to an image
      avatarRule: [
        v => !!v || 'This field is required',
        v => /.+\.(jpg|jpeg|png|gif)$/.test(v) || 'Avatar must be a valid image'
      ],
      DisplaynameValid: false,
      validBio: false,
      validEmail: false,
      passwordValid: false,
      validAvatar: false,
      validBadge: false

    }
  },
  mounted () {
    if (!this.user) {
      this.$router.push({ name: 'Login' })
    }
  },
  methods: {
    //   set new display name using feathers vuex
    updateDisplayname () {
      if (this.DisplaynameValid) {
        const data = {
          _id: this.user._id,
          displayname: this.form.displayname
        }
        const user = new this.$FeathersVuex.api.User(data)
        user.patch()
          .then(console.log(user))
          .catch(error => {
            this.error = error
            console.log(error)
          })
      }
    },
    updateBio () {
      if (this.validBio) {
        const data = {
          _id: this.user._id,
          bio: this.form.bio
        }
        const user = new this.$FeathersVuex.api.User(data)
        user.patch()
          .then(console.log(user))
          .catch(error => {
            this.error = error
            console.log(error)
          })
      }
    },
    updateEmail () {
      if (this.validEmail) {
        const data = {
          _id: this.user._id,
          email: this.form.email
        }
        const user = new this.$FeathersVuex.api.User(data)
        user.patch()
          .then(console.log(user))
          .catch(error => {
            this.error = error
            console.log(error)
          })
      }
    },
    updatePassword () {
      if (this.passwordValid) {
        const data = {
          _id: this.user._id,
          password: this.form.password
        }
        const user = new this.$FeathersVuex.api.User(data)
        user.patch()
          .then(console.log(user))
          .catch(error => {
            this.error = error
            console.log(error)
          })
      }
    },
    updateAvatar () {
      if (this.validAvatar) {
        const data = {
          _id: this.user._id,
          avatar: this.form.avatar
        }
        const user = new this.$FeathersVuex.api.User(data)
        user.patch()
          .then(console.log(user))
          .catch(error => {
            this.error = error
            console.log(error)
          })
      }
    },
    updateBadge () {
      if (this.validBadge) {
        const data = {
          _id: this.user._id,
          badge: this.form.badge
        }
        const user = new this.$FeathersVuex.api.User(data)
        user.patch()
          .then(console.log(user))
          .catch(error => {
            this.error = error
            console.log(error)
          })
      }
    }
  },
  computed: {
    ...mapGetters('users', { findUserInStore: 'find' }),
    user () {
      return this.findUserInStore(this.$route.params.id).data[0]
    }
  }
}
</script>
