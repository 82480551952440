<template>
    <div class="AdminHomePage" dark>
        <v-container>
          <!-- <v-row>
            <h1>Users Management: </h1>
            <v-col cols="12"></v-col>
            <v-card dark>
              <v-card-title>
                <span>See all users</span>
              </v-card-title>
              <v-card-actions>
                <v-btn color="primary" :to="{ name: 'Admin-Users-Management'}">Go</v-btn>
              </v-card-actions>
            </v-card>
          </v-row> -->
          <v-row>
            <h1><i>Soon!</i> Blog Management: </h1>
            <v-col cols="12"></v-col>
            <v-card dark>
              <v-card-title>
                <span>Posts Management</span>
              </v-card-title>
              <v-card-actions>
                <v-btn color="primary" :to="{ name: 'AllPosts'}" disabled class="disabled">All Posts</v-btn>
                <v-btn color="primary" :to="{ name: 'NewPost'}" disabled class="disabled">New Post</v-btn>
              </v-card-actions>
            </v-card>
          </v-row>
                    <v-row>
            <h1><i>Soon!</i> Friends: </h1>
            <v-col cols="12"></v-col>
            <v-card dark>
              <v-card-title>
                <span>Manage Friends Links</span>
              </v-card-title>
              <v-card-actions>
                <v-btn color="primary" :to="{ name: 'AllPosts'}" disabled class="disabled">See All</v-btn>
                <v-btn color="primary" :to="{ name: 'NewPost'}" disabled class="disabled">Add New</v-btn>
              </v-card-actions>
            </v-card>
          </v-row>
          <!-- <v-row>
            <h1>Content management</h1>
            <v-col cols="12"></v-col>
            <v-card dark>
              <v-card-title>
                <span>See all comments</span>
              </v-card-title>
            </v-card>
          </v-row>
          <v-row>
            <v-card dark>
              <v-card-title>
                <span>Home Page content</span>
              </v-card-title>
            </v-card>
            <v-card dark>
              <v-card-title>
                <span>About page Content</span>
              </v-card-title>
            </v-card>
            <v-card dark>
              <v-card-title>
                <span>Blog Header</span>
              </v-card-title>
            </v-card>
          </v-row> -->
        </v-container>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'AdminHome-Page',
  data () {
    return {
      //
    }
  },
  computed: {
    ...mapGetters('users', { findUserInStore: 'find' }),
    user () {
      return this.findUserInStore().data[0]
    }
  },
  methods: {
    //
  },
  created () {
    console.log(this.user)
    // if no user return error 404
    if (!this.user || !this.user.isAdmin) {
      this.$router.push('/404')
    }
  },
  beforeMount () {
    //
  }
}
</script>

<style scoped>
.row {
  color: aliceblue;
}
.v-btn--disabled {
  cursor: not-allowed !important;
}
</style>
