<template>
  <div class="blogHome">
    <v-container>
      <v-layout column>
        <v-row>
          <v-card v-for="article in articles" :key="article.title" class="mx-auto my-12" dark>
            <v-img
              height="250"
              src="https://cdn.vuetifyjs.com/images/cards/cooking.png"
            ></v-img>
            <v-card-title>
              <h1 class="headline">{{ article.title }}</h1>
            </v-card-title>
            <v-card-text>
              {{ article.description }}
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="primary"
                :href="`/blog/${article.slug}`"
                target="_blank"
                rel="noopener noreferrer"
              >
                <v-icon>mdi-book-open-outline</v-icon>
                &nbsp;Read
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-row>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'BlogHome',
  data () {
    return {
      loading: false,
      articles: [
        {
          title: 'Testing 1',
          description: 'This is a test article',
          background:
            'https://images.unsplash.com/photo-1518791841217-8f162f1e1131?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
          slug: 'testing-1'
        },
        {
          title: 'Testing 2',
          description: 'This is a test article',
          background:
            'https://images.unsplash.com/photo-1518791841217-8f162f1e1131?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
          slug: 'testing-2'
        },
        {
          title: 'Testing 3',
          description: 'This is a test article',
          background:
            'https://images.unsplash.com/photo-1518791841217-8f162f1e1131?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
          slug: 'testing-3'
        },
        {
          title: 'Testing 4',
          description: 'This is a test article',
          background:
            'https://images.unsplash.com/photo-1518791841217-8f162f1e1131?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
          slug: 'testing-4'
        }
      ]
    }
  }
}
</script>
