<template>
    <div class="holy_fuck_thats_a_login_page">
        <v-container>
            <v-layout column align-center>
                <v-row>
                    <div class="fuck_That_Alert ma-5">
                        <v-alert
                        v-if="error"
                        type="error"
                        dismissible>
                        {{ error.name }} ({{error.code}}) - {{error.message}}</v-alert>
                    </div>
                </v-row>
                <v-form v-if="!loading || !creating" v-model="formValid" @submit.prevent="login" dark>
                    <v-text-field
                    v-model="form.email"
                    :rules="emailRules"
                    label='Email *'
                    dark
                    required></v-text-field>
                    <v-text-field
                    v-model="form.password"
                    :rules="passwordRules"
                    suggested="new-password"
                    type='password'
                    label='Password'
                    dark
                    required></v-text-field>
                    <v-btn
                    color="primary"
                    :disabled="!formValid"
                    type='submit'
                    dark>
                        Login
                    </v-btn>
                </v-form>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'login-page',
  data () {
    return {
      form: {
        email: '',
        password: ''
      },
      formValid: false,
      error: null,
      creating: false,
      emailRules: [
        v => !!v || 'Email is required',
        v => /.+@.+\..+/.test(v) || 'Email must be valid'
      ],
      passwordRules: [
        v => !!v || 'Password is required',
        v => v.length >= 6 || 'Password must be at least 6 characters'
      ]
    }
  },
  methods: {
    login () {
      if (this.formValid) {
        this.$store.dispatch('auth/authenticate', {
          strategy: 'local',
          email: this.form.email,
          password: this.form.password
        })
          .then((data) => {
            if (data.user.emailVerified === false) {
              this.error.name = 'Authentication failed'
              this.error.code = '601'
              this.error.message = 'Email not verified'
            } else if (data.user.isBanned === true) {
              this.error.name = 'Authentication failed'
              this.error.code = '602'
              this.error.message = `Your account was associated with a Terms of Service violation of the follow category: ${data.user.banReason}. As result we have issued a temporary suspension from using all sites services. The suspension will be lifted in ${data.user.banExpiration}`
            } else {
              this.$router.push('/')
            }
          })
          .catch(error => {
            console.log(error)
            this.error = error
          })
      }
    }
  },
  beforeMount () {
    if (this.userData) {
      this.$router.push('/')
    }
  },
  computed: {
    ...mapState('auth', { loading: 'isAuthenticatePending' }),
    ...mapGetters('users', { findUserInStore: 'find' }),
    userData () {
      return this.findUserInStore().data[0]
    }
  }
}
</script>
