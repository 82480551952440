<template>
    <div class="That_Fucking_Registration_Page">
        <v-container>
            <v-layout column align-center>
                <v-row>
                    <v-col cols="12">
                        <v-alert
                        v-if="error"
                        :type="error.type"
                        :value="error.message"
                        dismissible></v-alert>
                    </v-col>
                </v-row>
                <v-form v-if="!loading || !creating" v-model="formValid" @submit.prevent="register">
                    <v-text-field
                    v-model="form.username"
                    :rules="notEmptyRule"
                    :counter="15"
                    label="Username *"
                    dark
                    required></v-text-field>
                    <v-text-field
                    v-model="form.displayname"
                    :rules="notEmptyRule"
                    :counter="35"
                    label="Display Name *"
                    dark
                    required></v-text-field>
                    <v-text-field
                    v-model="form.email"
                    :rules="emailRules"
                    label='Email *'
                    dark
                    required></v-text-field>
                    <v-text-field
                    v-model="form.password"
                    :rules="passwordRules"
                    suggested="new-password"
                    type='password'
                    label='Password'
                    dark
                    required></v-text-field>
                    <v-text-field
                    v-model="form.password_confirmation"
                    :rules="password_confirmationRules"
                    suggested="new-password"
                    type="password"
                    label="Confirm password"
                    dark
                    required></v-text-field>
                    <v-btn
                    color="primary"
                    :disabled="!formValid"
                    dark
                    type='submit'>
                        Register
                    </v-btn>

                </v-form>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'registration-page',
  data () {
    return {
      form: {
        email: '',
        username: '',
        displayname: '',
        password: '',
        password_confirmation: ''
      },
      formValid: false,
      notEmptyRule: [
        v => !!v || 'This field is required',
        v => (v && v.length <= 15) || 'Must be 15 characters or less'
      ],
      emailRules: [
        v => !!v || 'This field is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ],
      passwordRules: [
        v => !!v || 'This field is required',
        v => (v && v.length >= 6) || 'Must be at least 6 characters'
        // v => (v && /\d/.test(v)) || 'Must contain a number',
        // v => (v && /[A-Z]/.test(v)) || 'Must contain a capital letter',
        // eslint-disable-next-line no-useless-escape
        // v => (v && /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(v)) || 'Must contain a special character'
      ],
      password_confirmationRules: [
        v => !!v || 'This field is required',
        v => (v === this.form.password) || 'Passwords must match'
      ],
      error: ''
    }
  },
  methods: {
    register () {
      if (this.formValid) {
        const data = {
          email: this.form.email,
          username: this.form.username,
          displayname: this.form.displayname,
          password: this.form.password
        }
        const user = new this.$FeathersVuex.api.User(data)
        user.create()
          .then(() => {
            console.log(user)
            this.$router.push({ name: 'Login' })
          })
          .catch(error => {
            this.error.type = 'error'
            this.error.message = `${error.name}(${error.code}): ${error.message}`
          })
      }
    }
  },
  computed: {
    ...mapGetters('users', { findUserInStore: 'find' }),
    ...mapState('users', { loading: 'isFindPending' }),
    ...mapState('users', { creating: 'isCreatePending' })
  }
}
</script>
