<template>
  <v-footer
    color="primary lighten-1"
    padless
  >
    <v-row
      justify="center"
      no-gutters
    >
      <v-btn
        v-for="link in links"
        :key="link.name"
        color="white"
        text
        rounded
        class="my-2"
        :to="link.link"
      >
        {{ link.name }}
      </v-btn>
      <v-col
        class="primary lighten-2 py-4 text-center white--text"
        cols="12"
      >
        2019 - {{ new Date().getFullYear() }} — <span id="logo" @click="playA">Asthriona</span> <br>
        Code hosted on GitHub, DNS: Cloudflare, Hosting: Nishikino Network. <br>
        Domain Uptime: {{ Domaintime }}
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  data: () => ({
    Domaintime: 'Doing Math (∩ᄑ_ᄑ)⊃━☆ﾟ*･｡*･:≡( ε:)',
    links: [
      {
        name: 'Home',
        link: '/'
      },
      {
        name: 'Blog',
        link: '/blog'
      },
      {
        name: 'About',
        link: '/about'
      },
      {
        name: 'Contact',
        link: '/contact'
      },
      {
        name: 'Services',
        link: 'https://imgup.asthriona.com/'
      }
    ]
  }),
  name: 'TheFooter-Comp',
  methods: {
    // code inspirated from Aimee (https://www.mangoya.cn/)
    runTime () {
      const oldTime = new Date('2019-02-19 19:09:00')
      setInterval(() => {
        const nowTime = Date.now()
        const longTime = nowTime - oldTime
        const day = Math.floor(longTime / (1000 * 60 * 60 * 24))
        const hour = Math.floor(longTime / (1000 * 60 * 60)) % 24
        const minute = Math.floor(longTime / (1000 * 60)) % 60
        const second = Math.floor(longTime / 1000) % 60
        this.Domaintime = `${day} days ${hour} hours ${minute} minutes ${second} seconds`
      }, 1000)
    },
    playA () {
      const audio = new Audio('https://cdn.nishikino.xyz/asthriona/blog/static/gawr-gura-a.wav')
      audio.play()
    }
  },
  created () {
    this.runTime()
  }
}
</script>

<style scoped>
@font-face {
  font-family: 'Asthriona';
  src: url('../../assets/fonts/AsthrionaFont.ttf') format('truetype');
}
#logo {
  font-family: 'Asthriona';
  font-size: 1.5rem;
}
</style>
